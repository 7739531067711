/* eslint-disable no-loop-func */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

// validation libs
import { checkAlphabet }                  from './lib/alphabet';
import { checkKana }                      from './lib/kana';
import { checkAlphabetAndHyphen }         from './lib/alphabetAndHyphen';
import { checkEmail }                     from './lib/email';
import { checkBirthday }                  from './lib/birthday';
import { checkLengthMax, checkLengthMin } from './lib/length';
import { checkNumberAndHyphen }           from './lib/numberAndHyphen';
import { checkRequire }                   from './lib/require';
// import { checkSameAnotherKeyValue }       from './lib/sameAnotherKeyValue';
import { checkTelephoneNumber }           from './lib/telephoneNumber';
import { checkPostcode }                  from './lib/postcode';
import { mustCheck }                      from './lib/mustCheck';
import { checkUrl }                       from './lib/url';

export interface ValidationType {
  require?: boolean            // 必須判定
  lengthMax?: number           // 文字数上限（同値は許容）
  lengthMin?: number           // 文字数下限（同値は許容）
  // sameAnotherKeyValue?: string // 他のKeyとの同値判定をする
  numberAndHyphen?: boolean    // 郵便番号などの形式（数字とハイフンのみ）
  alphabet?: boolean           // 英数字のみの形式
  kana?: boolean               // カタカナであること
  alphabetAndHyphen?: boolean  // サブドメインなどの形式（英数字＋ハイフン）
  email?: boolean              // Email形式
  birthday?: boolean           // 誕生日入力判定（入力した年と同じ歳の生まれは不正とする）
  datetime?: boolean           // 日時形式
  postcode?: boolean           // 郵便番号形式 これを選択した場合には文字数制限不要
  telephoneNumber?: boolean    // 電話番号形式 これを選択した場合には文字数制限不要
  mustCheck?: boolean          // チェックボックスによるチェックの必須（未チェックの時にfalseになるので必須判定とは別にした）
  checkUrl?: boolean           // URL形式
}

/**
 * フォームに対してバリデーションチェックをするための関数
 * @param keyName: string     types/modelのstateのcolumn名に当たる 例：client型 => nameなど
 * @param modelState: any     types/modelのstate、フォームに設定している値がこっち
 * @param compaire_value: any 比較対象の値 例：メールアドレスの確認フォームの場合、確認じゃない方のメールアドレス
 */
export const checkValidation = (
  value: any,
  validation: any,
) => {
  // const validation: ValidationType = modelState[`${keyName}_extension`].rule;
  // const value: any = modelState[keyName];
  let messages = [];

  if (validation === undefined) return [];

  // 必須判定
  if (validation.require) {
    messages.push(checkRequire(value));
  }

  // 文字数上限
  if (validation.lengthMax) {
    messages.push(checkLengthMax(value, validation.lengthMax));
  }

  // 文字数下限
  if (validation.lengthMin) {
    messages.push(checkLengthMin(value, validation.lengthMin));
  }

  // 同値判定
  // if (validation.sameAnotherKeyValue) {
  //   const compaireKeyName = validation.sameAnotherKeyValue;
  //   messages.push(checkSameAnotherKeyValue(modelState, keyName, compaireKeyName));
  // }

  // 数字とハイフンのみ（郵便番号や電話番号形式）
  if (validation.numberAndHyphen) {
    messages.push(checkNumberAndHyphen(value));
  }

  // 英数字とハイフンのみ
  if (validation.alphabetAndHyphen) {
    messages.push(checkAlphabetAndHyphen(value));
  }

  // 英数字のみ
  if (validation.alphabet) {
    messages.push(checkAlphabet(value));
  }

  // カタカナのみ
  if (validation.kana) {
    messages.push(checkKana(value));
  }

  // Email形式
  if (validation.email) {
    messages.push(checkEmail(value));
  }

  // 誕生日
  if (validation.birthday) {
    messages.push(checkBirthday(value));
  }

  // 郵便番号形式
  if (validation.postcode) {
    messages.push(checkPostcode(value));
  }

  // 電話番号形式
  if (validation.telephoneNumber) {
    messages.push(checkTelephoneNumber(value));
  }

  // check boxのチェック必須
  if (validation.mustCheck) {
    messages.push(mustCheck(value));
  }

  // URL形式
  if (validation.url) {
    messages.push(checkUrl(value));
  }

  messages = messages.filter((v) => v); // 空文字を除去
  return messages;
};

/**
 * モデル型の値の中のvalidation失敗しているものエラーをnotifyする
 * @param modelState // types/modelの型のstateを入れる
 */
export const invalidModelMessageNotify = (
  modelStateArray: any,
) => {
  let invalidMessages = [] as Array<string>;

  modelStateArray.forEach((modelState: any) => {
    for (const extensionKeyName in modelState) {
      const keyExtension = /.*_extension$/.test(extensionKeyName); // extensionの有無で対象のcolumnのkeyを特定する

      if (keyExtension) {
        const valueKeyName = extensionKeyName.slice(0, -10); // _extensionを切り取った部分がkeyName
        const errorMessages = checkValidation(valueKeyName, modelState);

        errorMessages.forEach((message) => {
          invalidMessages = invalidMessages.concat(`${modelState[extensionKeyName].displayName}：${message}`);
        });
      }
    }
  })
  invalidMessages = invalidMessages.filter((v) => v); // 空文字を除去

  return invalidMessages;
};
